import React from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './EventHome.css'
import img1 from '../../assets/gallery/WhatsApp Image 2024-02-10 at 13.25.53_316000e1.jpg'
import img2 from '../../assets/gallery/WhatsApp Image 2024-02-10 at 13.10.00_0fc4e02f.jpg'
import img3 from '../../assets/gallery/WhatsApp Image 2024-02-10 at 13.10.00_1831f5bc.jpg'
import img4 from '../../assets/gallery/WhatsApp Image 2024-02-10 at 13.10.00_22729b8a.jpg'
import img5 from '../../assets/gallery/WhatsApp Image 2024-02-10 at 13.14.26_0c168b5f.jpg'
import img6 from '../../assets/gallery/WhatsApp Image 2024-02-10 at 13.19.11_8dc58f30.jpg'
import img7 from '../../assets/gallery/WhatsApp Image 2024-02-10 at 13.14.26_4bca8f94.jpg'
import img8 from '../../assets/gallery/WhatsApp Image 2024-02-10 at 13.14.26_ae27475a.jpg'
import img9 from '../../assets/gallery/WhatsApp Image 2024-02-10 at 13.23.32_e69ebbed.jpg'
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

const EventHome = () => {
	return (
		<div className='homeEventSection'>
			<Carousel id="carouselExampleControls" controls={true}>
      <Carousel.Item>
        <div className="cards-wrapper">
          <div className="card">
            <img src={img1} className="card-img-top" alt="..." />
          </div>
          <div className="card d-none d-md-block">
            <img src={img2} className="card-img-top" alt="..." />
          </div>
          <div className="card d-none d-md-block">
            <img src={img3} className="card-img-top" alt="..." />
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="cards-wrapper">
          <div className="card">
            <img src={img4} className="card-img-top" alt="..." />
          </div>
          <div className="card d-none d-md-block">
            <img src={img5} className="card-img-top" alt="..." />
          </div>
          <div className="card d-none d-md-block">
            <img src={img6} className="card-img-top" alt="..." />
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="cards-wrapper">
          <div className="card">
            <img src={img7} className="card-img-top" alt="..." />
          </div>
          <div className="card d-none d-md-block">
            <img src={img8} className="card-img-top" alt="..." />
          </div>
          <div className="card d-none d-md-block">
            <img src={img9} className="card-img-top" alt="..." />
          </div>
        </div>
      </Carousel.Item>
     
    </Carousel>
			<div className="headingContainer">
				<h1>Events</h1>
        <Link to='/gallery'>
				<p>Details<ArrowForwardIcon /></p>
        </Link>
			</div>
		</div>
	)
}

export default EventHome