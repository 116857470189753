import React from 'react'
import Slide from '../Slide/Slide'
import About from '../About/About'
import EventHome from '../Events/EventHome'
import Footer from '../Footer/Footer'
// import Academics from '../Academics/Academics'



const Home = () => {
  return (
    <div>
        {/* <Navigationbar/> */}
        <Slide/>
        <About/>
        <EventHome/>
    </div>
  )
}

export default Home