import React, { useState } from 'react'
import pcm from '../../../assets/Basics/chemistry.webp'
import pmc from '../../../assets/Basics/computer.jpg'
import cbz from '../../../assets/Basics/BOTONY.jpg'

const Degree = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const images = {  
    PCM: pcm,
    PMC: pmc,
    CBZ: cbz
  };

  const handleImageClick = (subject) => {
    setSelectedImage(images[subject]);
  };

  const handleOverlayClick = () => {
    setSelectedImage(null);
  };

  return (
    <div className='puSection'>
      <div className="coursesInPu">
        <p>
          A Bachelor of Science (B.Sc.) is a three-year undergraduate degree program. The program provides a solid foundation in science and a high level of learning experience in physical, mathematical, and chemical sciences.
        </p>
      </div>
      <div className="inDetail">
        <div className="part2">
          <h4>B.Sc Courses Offered </h4>
          <div className="container">
            <div className="row">
              <div className="col" onClick={() => handleImageClick('PCM')}>
                <h3>PCM</h3>
                <p>Physics | Chemistry | Mathametics</p>
              </div>
              <div className="col" onClick={() => handleImageClick('PMC')}>
                <h3>PMCs</h3>
                <p>Physics | Mathematics | Computer Science</p>
              </div>
              <div className="col" onClick={() => handleImageClick('CBZ')}>
                <h3>CBZ</h3>
                <p>Chemistry | Botany  | Zoology</p>
              </div>
            </div>
            {selectedImage && (
              <div className="overlay" onClick={handleOverlayClick}>
                <img src={selectedImage} alt="Selected Subject" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Degree

