import React, { useState } from 'react';
import './Facilites.css';
import backImg from '../../assets/Basics/facultybackground.jpg';
import biolab1 from '../../assets/Facilites/WhatsApp Image 2024-02-06 at 20.41.47_44ae753e.jpg';
import chemlab1 from '../../assets/Facilites/WhatsApp Image 2024-02-06 at 20.39.27_7363199b.jpg';
import cslab1 from '../../assets/Facilites/WhatsApp Image 2024-02-06 at 20.43.11_60de885a.jpg';
import transpo from '../../assets/Facilites/WhatsApp Image 2024-02-06 at 20.40.03_85a76ed4.jpg';
import camp1 from '../../assets/Facilites/IMG_20240201_105948.jpg';
import camp2 from '../../assets/Facilites/IMG_20240201_110023.jpg';
import camp3 from '../../assets/Facilites/IMG_20240201_110306.jpg';
import lib from '../../assets/Facilites/IMG_20240201_105521.jpg';
import transpo1 from '../../assets/Facilites/171e9b1e3b7d8d4b0416f4e6dea1ee793a814f66.jpg';
import transpo2 from '../../assets/Facilites/bfabeb66026e36541e43046eed55a52c3fdc5e62.jpg';

const Facilities = () => {
  const [activeLink, setActiveLink] = useState(null);
  const [selectedItem, setSelectedItem] = useState({
    name: 'Campus Infrastructure',
    images: [camp1, camp2, camp3],
    description: 'Our campus is equipped with state-of-the-art infrastructure, providing students with a comfortable and conducive learning environment. Our campus is designed to meet the highest safety standards and is managed by experienced staff.'
  });

  const items = [
    {
      name: 'Campus Infrastructure',
      images: [camp1, camp2, camp3],
      description: 'Our campus is equipped with state-of-the-art infrastructure, providing students with a comfortable and conducive learning environment. Our campus is designed to meet the highest safety standards and is managed by experienced staff.'
    },
    {
      name: 'Laboratory',
      images: [biolab1, chemlab1, cslab1],
      description: 'Our laboratories are equipped with the latest technology and equipment, providing students with the best practical learning experience. Our labs are designed to meet the highest safety standards and are managed by experienced lab assistants.'
    },
    {
      name: 'Library',
      images: [lib],
      description: 'Our library is a treasure trove of knowledge, with a wide range of books, journals, and periodicals. The library is designed to provide a peaceful and comfortable environment for students to study and research.'
    },
    {
      name: 'Transportation',
      images: [transpo, transpo1, transpo2],
      description: 'Our transportation facilities are designed to provide students with a safe and comfortable journey to and from the campus. Our buses are equipped with modern amenities and are managed by experienced drivers and staff.'
    }];

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setActiveLink(item.name);
  };

  return (
    <div className='facSection'>
      <div className="bagimg">
        <img src={backImg} alt="" />
        <p>What We Have Built</p>
      </div>
      <div className="mainHeading">
        <h1>Facilities</h1>
      </div>
      <div className="ourFacilities">
        <div className="mainContainer">
          <div className="campus-infrastructure">
            <div className="infrastructure-grid">
              {items.map((item) => (
                <div
                  key={item.name}
                  className={`infrastructure-item ${selectedItem === item ? 'selected' : ''}`}
                  onClick={() => handleItemClick(item)}
                  style={{ color: activeLink === item.name ? 'coral' : '' }}
                >
                  <h3>{item.name}</h3>
                </div>
              ))}
            </div>
            {selectedItem && (
              <div className="selected-item-details">
                <h2>{selectedItem.name}</h2>
                <p>{selectedItem.description}</p>
                {selectedItem && selectedItem.images && selectedItem.images.map((image, index) => (
                  <img key={index} src={image} alt={`${selectedItem.name}-${index}`} />
                ))}
                <button onClick={() => setSelectedItem(null)}>Close</button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facilities;
