import React from 'react'
import Academics from './Academics'
import { Outlet } from 'react-router-dom'

const SharedLayoutOfAcademics = () => {
    return (
        <>
        <Academics/>
        <Outlet/>
        </>
    )
}

export default SharedLayoutOfAcademics