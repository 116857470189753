import React, { useState } from 'react';
import './Academics.css';
import { Link } from 'react-router-dom';
import BackImg from '../../assets/Basics/Backgroundimg1.jpg';

const Academics = () => {
    const [activeLink, setActiveLink] = useState(null);

    const handleLinkClick = (link) => {
        setActiveLink(link);
    };

    return (
        <div className='acaSection'>
            <div className="bgimg">
                <img src={BackImg} alt="" />
                <p>
                    LEAP INTO SUCCESS NOW
                </p>
            </div>
            <div className="mainHeading">
                <h1>Our Courses</h1>
            </div>
            <div className="offeredCourses">
                <Link
                    to='/academics/pucourses'
                    onClick={() => handleLinkClick('puc')}
                    style={{ color: activeLink === 'puc' ? 'coral' : '' }}
                >
                    <div className="puc"><p>Pre-University Course</p></div>
                </Link>
                <Link
                    to='/academics/degree'
                    onClick={() => handleLinkClick('degree')}
                    style={{ color: activeLink === 'degree' ? 'coral' : '' }}
                >
                    <div className="becourse"><p>Degree Courses</p></div>
                </Link>
            </div>
        </div>
    );
};

export default Academics;
