
import './App.css';
import Academics from './components/Academics/Academics';
import Home from './components/Home/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navigationbar from './components/Navigationbar/Navigationbar';
import SharedLayoutOfAcademics from './components/Academics/SharedLayoutOfAcademics';
import Pucourses from './components/Academics/Pucourses/Pucourses';
import Degree from './components/Academics/Degree/Degree';
import Facilites from './components/Facilites/Facilites';
import About from './components/About/About';
import Vision from './components/About/aboo/Vision';
import Gallery from './components/Gallery/Gallery';
import Chairman from './components/About/aboo/Chairman';
import Principal from './components/About/aboo/Principal';
import Result from './components/Result/Result';
import Contact from './components/contact/Contact';
import Faculty from './components/Faculty/Faculty';
import Footer from './components/Footer/Footer';


function App() {
  return (
    <div className="App">
      <Navigationbar />
      
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/vision' element={<Vision />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/chairman' element={<Chairman />} />
          <Route path='/contact' element={<Contact/>} />
          <Route path='/principal' element={<Principal/>} />
          <Route path='/facilities' element={<Facilites />} />
          <Route path='/results' element={<Result/>} />
          <Route path='/faculty' element={<Faculty/>} />
          <Route path='/academics' element={<SharedLayoutOfAcademics />}>
            <Route path='/academics/pucourses' element={<Pucourses />} />
            <Route path='/academics/degree' element={<Degree />} />
          </Route>
        </Routes>
        <Footer/>
    </div>
  );
}

export default App;
