import React from 'react'
import { SocialIcon } from 'react-social-icons'
import './Footer.css'

const Footer = () => {
  return (
    <div className='footerSeciton'>
      <div className="fooContainer">
        <div className="detailsfield">
          <div className="know">
            <h3>Know Us</h3>
            <p>About Us</p>
            <p>Our Vision</p>
            <p>Our Mission</p>
            <p>Our Team</p>
          </div>
          <div className="contact">
            <h3>Contact Us</h3>
            <p>Shree Vidya College</p>
            <p>DR. Pramod Itagi Building, SB Temple Court Road Opp. IDBI Bank, Khuba Layout</p>
            <p>Kalaburgi, Karnataka</p>
            <p>Phone: 9886604987, 8880203830, 8660330955</p>
            <p>Email:shreevidya@gmail.com</p>
          </div>
          <div className="collegetime">
            <h3>College Hours</h3>
            <p>Monday - Saturday: 9:00 AM - 6:00 PM</p>
            <p>Sunday: 9:00 AM - 1:00 PM</p>
          </div>
          <div className="follow">
            <h3>Follow Us</h3> 
            <SocialIcon url="https://www.facebook.com/profile.php?id=61556527483441&mibextid=ZbWKwL" />
            <SocialIcon url="https://www.instagram.com/shreevidya582 " />
            
          </div>
        </div>
        <div className="copyRight">
          <p>© 2024 Shree Vidya College. All Rights Reserved.</p>
          <p className='developed'>Designed and Developed By Belageri Technologies Pvt Ltd.</p>
        </div>
      </div>
    </div>
  )
}

export default Footer