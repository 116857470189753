import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './Navigation.css';
import Logo from '../../assets/Basics/srividyaLogo.jpg';
import { Link } from 'react-router-dom';

const Navigationbar = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const handleDropdownItemClick = (path) => {
    setExpanded(false); // Close the navbar collapse when a dropdown item is clicked
    navigate(path);
    window.scrollTo(0, 0); // Reset page to top
  };

  const handleNavItemClick = () => {
    setExpanded(false); // Close the navbar collapse when a nav item is clicked
    window.scrollTo(0, 0); // Reset page to top
  };

  return (
    <div className='navSec'>
      <Navbar expand="lg" className="bg-body-tertiary" expanded={expanded}>
        <Container>
          <div className="LogoSection">
            <Navbar.Brand href="/"><img src={Logo} alt='logo'/></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
          </div>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} className="navItem" to="/" onClick={handleNavItemClick}>Home</Nav.Link>
              <NavDropdown title="About" id="basic-nav-dropdown">
                <NavDropdown.Item onClick={() => handleDropdownItemClick("/about")}>About SVC</NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleDropdownItemClick("/vision")}>
                  Vision & Mission
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleDropdownItemClick("/chairman")}>
                  Chairman's Message
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => handleDropdownItemClick("/principal")}>
                  Principal's Message
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} className="navItem" to="/academics/pucourses" onClick={handleNavItemClick}>Academics</Nav.Link>
              <Nav.Link as={Link} className="navItem" to="/faculty" onClick={handleNavItemClick}>Faculty</Nav.Link>
              <Nav.Link as={Link} className="navItem" to="/facilities" onClick={handleNavItemClick}>Facilities</Nav.Link>
              <Nav.Link as={Link} className="navItem" to='/results' onClick={handleNavItemClick}>Results</Nav.Link>
              <Nav.Link as={Link} className="navItem" to="/gallery" onClick={handleNavItemClick}>Gallery</Nav.Link>
              <Nav.Link as={Link} className="navItem" to="/contact" onClick={handleNavItemClick}>Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Navigationbar;
