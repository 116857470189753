// ContactForm.js
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "./Contact.css"
import BackImg from '../../assets/Basics/contactbg.jpeg'

const Contact = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [message, setMessage] = useState("");

  const onSubmit = (data) => {
    fetch("/api/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setMessage(data.message);
      })
      .catch((err) => {
        setMessage("Something went wrong. Please try again later.");
      });
  };

  return (
    <div className="contactContainer">
      <div className="bgimg">
        <img src={BackImg} alt="" />
        <p>
          Get In Touch

        </p>
      </div>
      <div className="mainContactForm">
        <h1>Contact Us</h1>
        <p>We would love to hear from you. Please fill out the form below and we will get back to you as soon as possible.</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" {...register("name", { required: true })} />
            {errors.name && <p className="error-message">Name is required</p>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })} />
            {errors.email && <p className="error-message">{errors.email.type === "required" ? "Email is required" : "Email is invalid"}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" rows="4" {...register("message", { required: true })}></textarea>
            {errors.message && <p className="error-message">Message is required</p>}
          </div>
          <button type="submit">Send</button>
        </form>
        {message && <p className="success-message">{message}</p>}
      </div>
    </div>
  );
};

export default Contact;
