import React from 'react'
import './About.css'
import img1 from '../../assets/Basics/WhatsApp Image 2024-01-01 at 19.51.44_2f345996.jpg'
import { Link } from 'react-router-dom'
const About = () => {
  return (
    <div className='aboutSection'>
      <div className="leftSide">
        <img className='main-img' src={img1} alt="" />
        <img className='hover-img' src={img1} alt="" />
      </div>

      <div className="rightSide">
        <h1>WELCOME TO SHREE VIDYA COLLEGE</h1>
        <p>Established in 2019, Shree Vidya College is dedicated to fostering a nurturing learning atmosphere that empowers students to unlock their utmost capabilities. As a premier pre-university institution, we are committed to delivering a holistic educational experience, equipping young adults with the skills and knowledge necessary for their future university endeavors. Our college prides itself on its exceptional learning environment, boasting a seasoned faculty, state-of-the-art facilities, and ample resources. We are dedicated to ensuring that our students receive the utmost support and opportunities for growth.</p>
      </div>
    </div>
  )
}

export default About