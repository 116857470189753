import React from 'react';
import './Faculty.css'; // You can create Faculty-specific styles in Faculty.css
import fac1 from '../../assets/Faculty/Screenshot 2024-02-27 230911.png'
import fac2 from '../../assets/Faculty/Screenshot 2024-02-27 230930.png'
import fac3 from '../../assets/Faculty/Screenshot 2024-02-27 230943.png'
import fac4 from '../../assets/Faculty/Screenshot 2024-02-27 231002.png'
import fac5 from '../../assets/Faculty/Screenshot 2024-02-27 231015.png'
import fac6 from '../../assets/Faculty/Screenshot 2024-02-27 231034.png'
import fac7 from '../../assets/Faculty/Screenshot 2024-02-27 231101.png'
import fac8 from '../../assets/Faculty/Screenshot 2024-02-27 231111.png'
import fac9 from '../../assets/Faculty/Screenshot 2024-02-27 231128.png'
import fac10 from '../../assets/Faculty/Screenshot 2024-02-27 231143.png'
import fac11 from '../../assets/Faculty/Screenshot 2024-02-27 231157.png'
import fac12 from '../../assets/Faculty/Screenshot 2024-02-27 231206.png'
import facmain from '../../assets/Faculty/pricipal.png'
import logo from '../../assets/Basics/logo.jpg'

const Faculty = () => {
    return (
        <div className="facultySection">
            <h2>Our Faculty</h2>
            <div className="pricipalbio">
                <img src={facmain} alt="" />
                <div className="detailsPrincipal">
                    <h3>Dr. Kaviraj C. Patil</h3>
                    <p>M.Sc, M.Phill, Ph.D</p>
                    <p>Principal</p>
                    <p>Biology - 17 Years Experience</p>
                </div>

            </div>
            <div className="fcultyDetails">
                <div className="faculty">
                    <img src={fac1} alt="" />
                    <h3>Dr. Vivekanand M</h3>
                    <p>M.Sc, Ph.D</p>
                    <p>Physics</p>
                    <p>11 Years Experience</p>
                </div>
                <div className="faculty">
                    <img src={fac2} alt="" />
                    <h3>Mahesh S. Kanaje</h3>
                    <p>M.Sc, B.Ed</p>
                    <p>Chemistry</p>
                    <p>13 Years Experience</p>
                </div>
                <div className="faculty">
                    <img src={fac3} alt="" />
                    <h3>Praveen B. Badri</h3>
                    <p>M.Sc, M.Phill</p>
                    <p>Mathematics</p>
                    <p>18 Years Experience</p>
                </div>
                <div className="faculty">
                    <img src={fac4} alt="" />
                    <h3>Ambika N. Noola</h3>
                    <p>MCA</p>
                    <p>Computer Science</p>
                    <p>12 Years Experience</p>
                </div>
                <div className="faculty">
                    <img src={fac5} alt="" />
                    <h3>Mallikarjun. M</h3>
                    <p>M.A, M.Ed</p>
                    <p>Economics</p>
                    <p>6 Years Experience</p>
                </div>
                <div className="faculty">
                    <img src={fac6} alt="" />
                    <h3>Vijaykumar. B</h3>
                    <p>M.Com, B.Ed</p>
                    <p>Business Studies</p>
                    <p>6 Years Experience</p>
                </div>
                <div className="faculty">
                    <img src={fac7} alt="" />
                    <h3>Gururaj. V</h3>
                    <p>M.Com, B.Ed</p>
                    <p>Accountancy</p>
                    <p>6 Years Experience</p>
                </div>
                <div className="faculty">
                    <img src={fac8} alt="" />
                    <h3>Prabha Patil</h3>
                    <p>MCA</p>
                    <p>Computer Science</p>
                    <p>4 Years Experience</p>
                </div>
                <div className="faculty">
                    <img src={fac9} alt="" />
                    <h3>Vimala. G</h3>
                    <p>M.Com, B.Ed</p>
                    <p>Business Studies/Accountancy</p>
                    <p>6 Years Experience</p>
                </div>
                <div className="faculty">
                    <img src={fac10} alt="" />
                    <h3>Pragnya. D</h3>
                    <p>M.A, B.Ed</p>
                    <p>English</p>
                    <p>10 Years Experience</p>
                </div>
                <div className="faculty">
                    <img src={fac11} alt="" />
                    <h3>Sudha. S</h3>
                    <p>M.A, M.Phill, B.Ed</p>
                    <p>Kannada</p>
                    <p>8 Years Experience</p>
                </div>
                <div className="faculty">
                    <img src={fac12} alt="" />
                    <h3>krishankumar. K</h3>
                    <p>M.A, M.Phill, B.Ed</p>
                    <p>Hindi</p>
                    <p>21 Years Experience</p>
                </div>
            </div>
        </div>
    );
};

export default Faculty;