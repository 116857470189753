import React, { useState } from 'react'
import './pucourses.css'
import pcmb from '../../../assets/Basics/Biology.jpg'
import pcmc from '../../../assets/Basics/comp.jpg'
import cbea from '../../../assets/Basics/commerce.jpg'

const Pucourses = () => {
  const [selectedImage, setSelectedImage] = useState(pcmb);

  const images = {
    PCMB: pcmb,
    PCMC: pcmc,
    CBEA: cbea
  };

  const handleImageClick = (subject) => {
    setSelectedImage(images[subject]);
  };

  const handleOverlayClick = () => {
    //setSelectedImage(null);
  };

  return (
    <div className='puSection'>
      <div className="coursesInPu">
        <p>The duration of the Pre-University course is spread over a period of 2 academic years.Medium of instruction is English. However, students may, at their option, answer in either English or Kannada in the public examination.</p>
        <p>The course of study is in 2 Parts:<br />Part – I: Language <br />Part – II: Core Subjects<br /></p>
      </div>
      <div className="inDetail">
        <div className="part1">
          <h4>Part I - Language</h4>
          <p>English with an option for Kannada, and Hindi.</p>
        </div>
        <div className="part2">
          <h4>Part II - Core Subjects</h4>
          <div className="container">
            <div className="row">
              <div className="col" onClick={() => handleImageClick('PCMB')}>
                <h3>PCMB</h3>
                <p>Physics | Chemistry | Mathametics | Biology</p>
              </div>
              <div className="col" onClick={() => handleImageClick('PCMC')}>
                <h3>PCMCs</h3>
                <p>Physics | Chemistry | Mathematics | Computer Science</p>
              </div>
              <div className="col" onClick={() => handleImageClick('CBEA')}>
                <h3>EBACs</h3>
                <p>Economics | Bussiness Studies  | Accountancy  | Computer Science </p>
              </div>
            </div>
            {selectedImage && (
              <div className="overlay" onClick={handleOverlayClick}>
                <img src={selectedImage} alt="Selected Subject" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pucourses

