import React from 'react'
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
// import ExampleCarouselImage from 'components/ExampleCarouselImage';

// import FirstSlide from '../../assets/Basics/facultybackground.jpg'
// import SecondSlide from '../../assets/Basics/Untitled.png'
// import ThirdSlide from '../../assets/Basics/WhatsApp Image 2024-01-01 at 19.51.44_2f345996.jpg'
import './Slide.css'



const data = [
  {
    image: require('../../assets/Slide/Slide1.jpg'),

  },
  {
    image: require('../../assets/gallery/WhatsApp Image 2024-02-10 at 13.20.50_0ef25d1c.jpg'),
    caption: "Caption",
    description: "Description Here"
  },
  {
    image: require('../../assets/gallery/WhatsApp Image 2024-02-10 at 13.14.26_0c168b5f.jpg'),
    caption: "Caption",
    description: "Description Here"
  },
  {
    image: require('../../assets/gallery/WhatsApp Image 2024-02-10 at 13.33.39_f09a309a.jpg'),
    caption: "Caption",
    description: "Description Here"
  },
]

const Slide = () => {

  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const carouselItemStyle = {
    height: '600px',
  };
  // const ImgStyle ={
  //   objectFit: 'contain',
  // };
  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
      {data.map((slide, i) => {
        return (
          <Carousel.Item style={carouselItemStyle}>
            <img
              className="d-block w-100"
              src={slide.image}
              alt="slider image"
              // style={ImgStyle}
            />
            {/* <Carousel.Caption>
              <h3>{slide.caption}</h3>
              <p>{slide.description}</p>
            </Carousel.Caption> */}
          </Carousel.Item>
        )
      })}

    </Carousel>
  )
}

export default Slide