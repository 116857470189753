import React from 'react'
import './Result.css'
import BackImg from '../../assets/Basics/resultBg1.jpeg'
import Science22 from '../../assets/Results/Science-2022.png'
import Commerce22 from '../../assets/Results/Commerce.png'
import Science23 from '../../assets/Results/Science-2023.png'
import Achivers from '../../assets/Results/Screenshot 2024-02-26 012705.png'
import neetrank from '../../assets/Results/Screenshot 2024-02-27 005604.png'
import jeerank from '../../assets/Results/Screenshot 2024-02-27 005620.png'
import Science1st2024 from '../../assets/Results/Science1sr2024.jpg'
import Science2nd2024 from '../../assets/Results/Science2nd2024.jpg'
import commerce1st2024 from '../../assets/Results/commerce1st2024.jpg'
import commerce2nd2024 from '../../assets/Results/commerce2nd2024.jpg'
import topp from '../../assets/Results/result2024.jpg'


const Result = () => {
  return (
    <div className='resultSection'>
      <div className="bgimg">
        <img src={BackImg} alt="" />
        <p>
          UNVEIL EXCELLENCE

        </p>
      </div>
      <div className="mainHeading">
        <div className="neetRanker">
          <img src={neetrank} alt="" />
          <p>674</p>
          <p>District Rank 1</p>
        </div>
        <div className="heading">
          <h1>
            Toppers
          </h1>
          <p>Your Unmatched Track Record</p>
        </div>
        <div className="jeeRanker">
          <img src={jeerank} alt="" />
          <p>97.6%</p>
          <p>District Rank 1</p>
        </div>
      </div>
      <div className="result">
        <div className="r2024">
          <h2>2024 - Results</h2>
          <div className="topranker">
            <div className="science">
              <h4>Science</h4>
              <img src={Science1st2024} alt="" />
              <img src={Science2nd2024} alt="" />
            </div>

            <div className="commerce">
              <h4>Commerce</h4>
              <img src={commerce1st2024} alt="" />
              <img src={commerce2nd2024} alt="" />
            </div>


          </div>
          <div className="topp">
            <img src={topp} alt="" />
          </div>
        </div>
        <div className="r2023">

          <div className="Science-2023">
            <img src={Science23} alt="" />
          </div>
          <div className="achivers">
            <img src={Achivers} alt="" />
          </div>
        </div>
        <div className="r2022">
          <div className="Science-2022">
            <img src={Science22} alt="" />
          </div>
          <div className="Commerce-2022">
            <img src={Commerce22} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Result