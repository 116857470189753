import React from 'react'
import hny from '../../assets/gallery/WhatsApp Image 2024-02-10 at 13.10.00_0fc4e02f.jpg'
import img1 from '../../assets/gallery/WhatsApp Image 2024-02-10 at 13.10.00_1831f5bc.jpg'
import img2 from '../../assets/gallery/WhatsApp Image 2024-02-10 at 13.10.00_22729b8a.jpg'
import img3 from '../../assets/gallery/WhatsApp Image 2024-02-10 at 13.14.26_0c168b5f.jpg'
import img4 from '../../assets/gallery/WhatsApp Image 2024-02-10 at 13.14.26_2149053e.jpg'
import img5 from '../../assets/gallery/WhatsApp Image 2024-02-10 at 13.14.26_4bca8f94.jpg'
import img6 from '../../assets/gallery/WhatsApp Image 2024-02-10 at 13.14.26_7cd2ef22.jpg'
import img7 from '../../assets/gallery/WhatsApp Image 2024-02-10 at 13.14.26_a8ff3838.jpg'
import img8 from '../../assets/gallery/WhatsApp Image 2024-02-10 at 13.14.26_ae27475a.jpg'
import img9 from '../../assets/gallery/WhatsApp Image 2024-02-10 at 13.19.11_8dc58f30.jpg'
import img10 from '../../assets/gallery/WhatsApp Image 2024-02-10 at 13.20.50_0ef25d1c.jpg'
import img11 from '../../assets/gallery/WhatsApp Image 2024-02-10 at 13.20.50_d9e7d618.jpg'
import img12 from '../../assets/gallery/WhatsApp Image 2024-02-10 at 13.22.13_0ec811f1.jpg'
import img13 from '../../assets/gallery/WhatsApp Image 2024-02-10 at 13.22.13_dc81333a.jpg'
import img14 from '../../assets/gallery/WhatsApp Image 2024-02-10 at 13.23.32_e69ebbed.jpg'
import img15 from '../../assets/gallery/WhatsApp Image 2024-02-10 at 13.25.53_316000e1.jpg'
import img16 from '../../assets/trip photos/03680b85901f8b8fbc8a18cf63dce2a3776a630c.jpg'
import img17 from '../../assets/trip photos/195ba6e31684fc1063b8daf7c3368afbb0b26f50.jpg'
import img18 from '../../assets/trip photos/1e680d89158f304e91ef62f74a0697d454da270f.jpg'
import img19 from '../../assets/trip photos/2031d9ac6640a0927dbc78b4e6a4808621b94739.jpg'
import img20 from '../../assets/trip photos/2ee5e383678f722087ab452a3445bfa11edac7fc.jpg'
import img21 from '../../assets/trip photos/3774fd08d112fea475ba36d241b9ca443c8efc85.jpg'
import img22 from '../../assets/trip photos/abe0f288ef2bd5287ae81854b2934dacb9bd9ad5.jpg'
import img23 from '../../assets/trip photos/7bcc280ac0dd3e01c0bdf43d40069286350e9ab5.jpg'
import img24 from '../../assets/trip photos/a09936243c9a30f1125929e9254224914fa70e81.jpg'
import './Gallery.css'


const Gallery = () => {
    return (
        <div className='gallerySection'>
            <div className='main-heading'>Gallery</div>
            <div className='img-section grid'>
                <img className='img' alt='h0' src={hny} />
                <img className='img' alt='h0' src={img1} />
                <img className='img' alt='h1' src={img2} />
                <img className='img' alt='h2' src={img3} />
                <img className='img' alt='h3' src={img4} />
                <img className='img' alt='h4' src={img5} />
                <img className='img' alt='h5' src={img6} />
                <img className='img' alt='h6' src={img7} />
                <img className='img' alt='h7' src={img8} />
                <img className='img' alt='h8' src={img9} />
                <img className='img' alt='h9' src={img10} />
                <img className='img' alt='h9' src={img11} />
                <img className='img' alt='h9' src={img12} />
                <img className='img' alt='h9' src={img13} />
                <img className='img' alt='h9' src={img14} />
                <img className='img' alt='h9' src={img15} />
                <img className='img' alt='h9' src={img16} />
                <img className='img' alt='h9' src={img17} />
                <img className='img' alt='h9' src={img18} />
                <img className='img' alt='h9' src={img19} />    
                <img className='img' alt='h9' src={img20} />
                <img className='img' alt='h9' src={img21} />
                <img className='img' alt='h9' src={img22} />
                <img className='img' alt='h9' src={img23} />
                <img className='img' alt='h9' src={img24} />

            </div>
        </div>
    )
}

export default Gallery;