    import React from 'react'
    import './Vision.css'


    const Vision = () => {
        return (
            <div className='visionSection'>
                <h2>Vision & Mission </h2>
                <div className="visContainer">
                    <h3>Vision</h3>
                    <p>Vision: To achieve academic excellence through innovative teaching learning practice, identify the hidden talents provide opportunities for students to realize their free potential.</p>
                    <h3>Mission</h3>
                    <p>We Endeavour to be the right mentor for IIT-JEE, NEET, NATA, NDA aspirants while also helping them develop a global perspective, with a keen interest in community life. We also facilitate students' success in various competitive exams in India like NEET/JEE/NDA/KCET.</p>
                </div>
            </div>
        )
    }

    export default Vision