import React from 'react'
import './Chairman.css'
import ChairmanImg from '../../../assets/Basics/Screenshot 2024-03-14 105753.png'

const Principal = () => {
    return (
        <div className='ChaiSection'>
            <div className="chairImg">
                <img src={ChairmanImg} alt="" />
            </div>
            <div className="chairmanMsg">
                <h1>Principal's Message</h1>

                <h3>Dr. Kaviraj C. Patil</h3>
                <span> M.Sc. M.Phil. Ph.D.</span>
                <p>
                    Education is not just an act of acquiring knowledge but learning a skill to lead life and form one's personality. At our college, we focus on discovering, developing, and drawing out the hidden talents and the magic link dormant inside all of our students. From academics to co-curricular activities, perseverance and a never-say-die spirit are entrenched in the heart of every student, making them not only good students but brilliant human beings.                </p>
            </div>
        </div>
    )
}

export default Principal