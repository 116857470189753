import React from 'react'
import './Chairman.css'
import ChairmanImg from '../../../assets/Basics/Screenshot 2024-03-14 105740.png'

const Chairman = () => {
    return (
        <div className='ChaiSection'>
            <div className="chairImg">
                <img src={ChairmanImg} alt="" />
            </div>
            <div className="chairmanMsg">
                <h1>Chairman's Message</h1>

                <h3>Dr. Pramod Itagi</h3>
                <p>
                    We aim at establishing benchmarks in academic excellence and holistic grooming to help our students to meet career challenges in the increasingly globalized economic conditions. We look forward to welcoming you to SHREE VIDYA PU COLLEGE of advance studies and helping you to shape your future by turning your dreams into reality and preparing you to face the challenges ahead.
                </p>
            </div>
        </div>
    )
}

export default Chairman